export enum ButtonsTypes {
  CONTAINED_GREEN = 'CONTAINED_GREEN',
  CONTAINED_GRAY = 'CONTAINED_GRAY',
  CONTAINED_WHITE = 'CONTAINED_WHITE',

  CONTAINED_LIGHT_RED = 'CONTAINED_LIGHT_RED',

  // CONTAINED_RED = 'CONTAINED_RED',
  // CONTAINED_BLUE = 'CONTAINED_BLUE',
  // OUTLINED_RED = 'OUTLINED_RED',
  // OUTLINED_GREEN = 'OUTLINED_GREEN',
  // OUTLINED_BLUE = 'OUTLINED_BLUE',
  // OUTLINED_PURPLE = 'OUTLINED_PURPLE',
}

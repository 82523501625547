export const theme = {
  colors: {
    white: '#FFFFFF',
    background: '#F3F2F7',
    background_hover: '#E7E5EE',
    border: '#E0DEE9',
    border1: '#EBE8F7',
    black: '#35343D',
    gray: '#565B68',
    gray1: '#979EA5',
    gray2: '#7E828E',
    gray3: '#ACA8BD',
    gray4: '#D8DDE2',
    green: '#2DBB6E',
    light_green_hover: '#48D88A',
    green_background: '#E0F5E9',
    red: '#FF4B5A',
    red1: '#E3505C',
    red_background: '#F5E0E0',
    blue: '#379FF2',
    blue1: '#4DAAF3',
    blue2: '#63B4F5',
    blue3: '#7ABFF6',
    blue4: '#8BCFFF',
    blue_background: '#E1F1FD',
    purple: '#A15BC2',
    purple_background: '#F1E7F6',
  },
  borderRadius: {
    radius8: '8px',
  },
};

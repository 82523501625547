export enum ModalsTypes {
  REPLENISH_ACCOUNT = 'REPLENISH_ACCOUNT',
  INVEST = 'INVEST',
  INVEST_SUCCESS = 'INVEST_SUCCESS',
  INVEST_ERROR = 'INVEST_ERROR',
  WITHDRAW = 'WITHDRAW',
  WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS',
  WITHDRAW_VERIFICATION = 'WITHDRAW_VERIFICATION',
  WITHDRAW_VERIFICATION_SUCCESS = 'WITHDRAW_VERIFICATION_SUCCESS',
}

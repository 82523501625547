export enum GeneralView {
  REDIRECT = 'REDIRECT',
  USER = 'USER',
  ERROR = 'ERROR',
  INVITE = 'INVITE',
}

export enum UserView {
  USER_CONTRACTS = 'USER_CONTRACTS',
  USER_INVESTMENT = 'USER_INVESTMENT',
  USER_PARTNERS = 'USER_PARTNERS',
  USER_HISTORY = 'USER_HISTORY',
  USER_PROFILE = 'USER_PROFILE',
}

export enum ErrorView {
  ERROR_404 = 'ERROR_404',
  ERROR_401 = 'ERROR_401',
  ERROR_500 = 'ERROR_500',
}
